<template>
  <b-card>
    <b-card-body class="invoice-padding form-item-section">
      <div ref="form" class="repeater-form">
        <b-row ref="row" class="pb-2 pt-2">
          <!-- Item Form -->
          <!-- ? This will be in loop => So consider below markup for single item -->
          <b-col cols="12">
            <h4 class="text-center">
              <feather-icon icon="CheckIcon" size="18" /> Libelle de la
              prospection:
              <span class="text-primary">{{ prospections.libelle }}</span>
            </h4>
            <h4 class="text-center">
              <feather-icon icon="CalendarIcon" size="18" /> Date debut de la
              prospection:
              <span class="text-primary">{{ prospections.date_debut }}</span>
            </h4>
            <h4 class="text-center">
              <feather-icon icon="CalendarIcon" size="18" /> Date fin de la
              prospection:
              <span class="text-primary">{{ prospections.date_fin }}</span>
            </h4>
            <h4 class="text-center" v-if="prospections.description !== null">
              <feather-icon icon="CheckIcon" size="18" /> Motif de la
              prospection:
              <span class="text-primary">{{
                prospections.description !== null
                  ? prospections.description
                  : ""
              }}</span>
            </h4>
            <div
              class="d-flex border rounded"
              v-for="(prospect, index) in prospections.prospects"
              :key="prospect.id"
            >
              <b-row class="flex-grow-1 p-2">
                <!-- Single Item Form Headers -->
                <b-col cols="4" lg="4"> </b-col>
                <h5>Prospect #{{ index + 1 }}</h5>
                <b-col cols="12" lg="12">
                  <span class="font-weight-bold">Nom et prenoms:</span>
                  {{ prospect.nom }} {{ prospect.prenom }}
                </b-col>
                <b-col cols="12" lg="12">
                  <span class="font-weight-bold">Email:</span>
                  {{ prospect.email }}
                </b-col>
                <b-col cols="12" lg="12">
                  <span class="font-weight-bold">Contact:</span>
                  {{ prospect.indicateur }} {{ prospect.contact }}
                </b-col>
                <b-col cols="12" lg="12">
                  <span class="font-weight-bold">Adresse:</span>
                  <p v-if="prospect.localisation !== null">
                    {{
                      prospect.localisation.formatted_address !== null
                        ? prospect.localisation.formatted_address
                        : ""
                    }}
                  </p>
                </b-col>
                <b-col cols="12" lg="12">
                  <span class="font-weight-bold">Statut:</span>
                  {{ prospect.status_user }}
                </b-col>
                <b-col cols="12" lg="12">
                  <span class="font-weight-bold"
                    >Type {{ prospect.status_user }}:</span
                  >
                  {{ prospect.type_client }}
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- DUPLICATEUR -->
      <div ref="form" class="repeater-form" :style="{ height: trHeight }">
        <b-row
          v-for="(item, index) in multiProspects"
          :key="index"
          ref="row"
          class="pb-2"
        >
          <!-- Item Form -->
          <!-- ? This will be in loop => So consider below markup for single item -->
          <b-col cols="12">
            <div class="d-flex border rounded">
              <b-row class="flex-grow-1 p-2">
                <!-- compte -->
                <b-col cols="12" md="12" class="m-auto">
                  <b-form-group label="" label-for="register-libelle">
                    <label for=""
                      >Prospect<span class="p-0 text-danger h6">*</span></label
                    >
                    <v-select
                      v-model="item.selectedProspect"
                      placeholder="Selectionnez un prospect"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      rules="required"
                      label="nom"
                      :options="prospectList"
                      @input="(val) => updateItemForm(index, val)"
                    >
                      <template #list-header>
                        <li
                          v-b-toggle.sidebar-invoice-add-new-customers-fournisseur
                          class="add-new-client-header d-flex align-items-center my-50 zindex-100"
                        >
                          <feather-icon icon="PlusIcon" size="16" />
                          <span class="align-center ml-25"
                            >créer un nouveau prospect</span
                          >
                        </li>
                      </template>
                      <template v-slot:option="option">
                        {{ option.nom }}
                        {{ option.prenoms }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div
                class="d-flex flex-column justify-content-between border-left py-50 px-25"
              >
                <feather-icon
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  @click="removeItem(index)"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="12" style="">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="md"
            variant="primary"
            @click="addNewItemInItemForm"
          >
            Ajouter un prospect ou client
          </b-button>
        </b-col>
      </b-row>
      <!-- Form Actions -->
      <div class="text-center mt-2" v-if="this.multiProspects.length !== 0">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="mr-2"
          @click="retour"
        >
          Retour
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="addNewProspect"
           :disabled='loading===true ? true :false' 
        >
         <div v-if="loading===true" class="spinner-border text-primary"></div>
          <span v-else> Ajouter</span>
        </b-button>
      </div>

      <!-- SIDEBAR FORMULAIRE POUR AJOUTER UN Prospect -->
      <b-sidebar
        id="sidebar-invoice-add-new-customers-fournisseur"
        sidebar-class="sidebar-lg"
        ref="sidebarProspect"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
      >
        <template #default="{ }">
          <!-- Header -->
          <div
            id="saveFourniseur__id"
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              Ajouter un Prospect
            </h5>

            <!-- <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" /> -->
          </div>

          <!-- Body -->
          <b-form @submit.prevent class="p-2">
            <!-- nom -->
            <b-form-group label="" label-for="register-nom">
              <label> Nom<span class="p-0 text-danger h6">*</span> </label>
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  id="register-nom"
                  @input="validateNom"
                  v-model="nom"
                  name="register-nom"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Bazaroph"
                />
                <small
                  :class="valideNom ? 'block' : 'none'"
                  class="text-danger"
                >
                  Vous devez renseigner le nom du prospect
                </small>
              </validation-provider>
            </b-form-group>

            <!-- prenom -->
            <b-form-group label="" label-for="register-prenom">
              <label>
                Prénoms
              </label>
              <validation-provider #default="{ errors }" name="prenom" rules="">
                <b-form-input
                  id="register-prenom"
                  v-model="prenom"
                  name="register-prenom"
                  :state="errors.length > 0 ? false : null"
                  placeholder="johndoe"
                />
                <!-- <small :class="validePrenom ? 'block' : 'none'" class="text-danger">
                                Vous devez renseigner le prénom du prospect
                            </small> -->
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group label="Email" label-for="register-email">
              <validation-provider #default="{ errors }" name="Email" rules="">
                <b-form-input
                  id="register-email"
                  v-model="email"
                  type="email"
                  name="register-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                />
                <!-- <small :class="valideEmail ? 'block' : 'none'" class="text-danger">
                                Veuillez entrer un email valide
                            </small> -->
              </validation-provider>
            </b-form-group>

            <!-- contact -->
            <b-form-group label="" label-for="register-contact">
              <label> Contact<span class="p-0 text-danger h6">*</span> </label>
              <validation-provider
                #default="{ errors }"
                name="contact"
                rules="required"
              >
                <vue-tel-input
                  id="register-contact"
                  @country-changed="changer"
                  @input="vider"
                  @validate="contactEntier($event)"
                  v-model="phone"
                  name="register-contact"
                  :state="errors.length > 0 ? false : null"
                  placeholder="000-000-000-000"
                />
                <small
                  :class="valideContact ? 'block' : 'none'"
                  class="text-danger"
                >
                  Veuillez renseigner votre contact
                </small>
                <small
                  :class="valideContactNumber ? 'block' : 'none'"
                  class="text-danger"
                >
                  Ce numéro de téléphone n'est pas valide
                </small>
              </validation-provider>
            </b-form-group>

            <!-- Localisation -->
            <b-libelle class="red">Localisation</b-libelle>
            <b-form-group
              label-for="entreprise-localisation"
              class="localisation"
            >
              <validation-provider
                #default="{ }"
                name="localisation"
                rules="required"
              >
                <div id="custom-search-input" class="mb-1">
                  <div class="input-group">
                    <input
                      id="autocomplete_search"
                      @input="initialize"
                      v-model="localisation"
                      name="autocomplete_search"
                      type="text"
                      class="form-control"
                      placeholder="Votre adresse"
                    />
                    <input type="hidden" name="lat" />
                    <input type="hidden" name="long" />
                  </div>
                </div>
                <small
                  :class="valideLocalisation ? 'block' : 'none'"
                  class="text-danger"
                >
                  Veuillez entrer la localisation du client
                </small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label>
                Status du client<span class="p-0 text-danger h6">*</span>
              </label>
              <v-select
                @input="validateStatus"
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="option"
              />
              <small
                :class="valideStatus ? 'block' : 'none'"
                class="text-danger"
              >
                Vous devez renseigner le status du prospect
              </small>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-b-toggle.sidebar-invoice-add-new-customers
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="saveProspect"
              >
                Ajouter
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
              >
                Annuler
              </b-button>
            </div>
          </b-form>
        </template>
      </b-sidebar>
      <!-- end sidebar add prospect -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BModal,
  BFormInput,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import URL from "@/views/pages/request";
import axios from "axios";
import { VueTelInput } from "vue-tel-input";
// import useUsersList from '../users-list/useUsersList'


export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    VueTelInput,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      prospectionAll: [],
      prospection_index: "",
      prospectList: [],
      //Add new prospect
      __prospection_id: 0,
      multiProspects: [],

      multiProspectItem: {
        selectedProspect: "",
        selectedProspectId: "",
      },
      //variable localStorage
      prospections: "",

      //sidebarBarVariable
      //prospect

      nom: "",
      prenom: "",
      email: "",
      localisation: "",
      contact: "",
      phone: "",
      contactFinal: "",
      indicatifFinal: "",
      selected: "Quel est votre status",
      option: [{ title: "Particulier" }, { title: "Entreprise" }],

      valideNom: false,

      valideProspection: false,
      validePrenom: false,
      valideContact: false,
      valideEmail: false,
      valideContactNumber: false,
      validePassword: false,
      valideLocalisation: false,
      valideContactNumber: false,

      loading:false,
    };
  },

  async mounted() {
    document.title = "Detail-Prospection";
    google.maps.event.addDomListener(window, "load", initialize);

    this.prospections = JSON.parse(localStorage.getItem("prospection"));
    console.log(this.prospections);
    try {
      await axios.get(URL.PROSPECT_LIST).then((response) => {
        this.prospectList = response.data.prospects;
        //    this.pTotal = this.prospects.length
      });
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    vider() {
      this.valideContact = false;
    },

    //localisation
    initialize() {
      var input = document.getElementById("autocomplete_search");
      var autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function() {
        var place = autocomplete.getPlace();
        // place variable will have all the information you are looking for.
        $("#lat").val(place.geometry["location"].lat());
        $("#long").val(place.geometry["location"].lng());
        this.localisation = place;
        localStorage.setItem("place", JSON.stringify(place));
        // console.log(this.address)
      });
    },

    updateItemForm(index, val) {
      const { id } = val;
      this.multiProspects[index].selectedProspectId = id;
      console.log(this.multiProspects[0].selectedProspect.nom);
      // console.log('aaa:',this.multiProspects[0].selectedProspect)
    },

    addNewItemInItemForm() {
      this.multiProspects.push(
        JSON.parse(JSON.stringify(this.multiProspectItem))
      );
    },

    removeItem(index) {
      this.multiProspects.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },

    //valide newaddpROSPECT SIDBAR

    //validation
    validateContact() {
      this.contactFinal = this.contact.formatted;
      if (!this.contactFinal) {
        this.valideContact = true;
        this.erreur = true;
      } else {
        this.valideContact = false;
        this.erreur = false;
      }

      if (this.contactFinal && this.contactFinal.length < 8) {
        this.valideContactNumber = true;
        this.erreur = true;
      } else {
        this.valideContactNumber = false;
        this.erreur = false;
      }
    },

    validateNom() {
      if (!this.nom) {
        this.valideNom = true;
        this.erreur = true;
      } else {
        this.valideNom = false;
        this.erreur = false;
      }
    },

    validateStatus() {
      if (this.selected === "Quel est votre status") {
        this.valideStatus = true;
        this.erreur = true;
      } else {
        this.valideStatus = false;
        this.erreur = false;
      }
    },
    validateLocalisation() {
      if (!this.localisation) {
        this.valideLocalisation = true;
        this.erreur = true;
      } else {
        this.valideLocalisation = false;
        this.erreur = false;
      }
    },

    contactEntier(e) {
      this.contact = e;
      //    console.log(this.phone)
    },
    changer(e) {
      // console.log(this.phone)
      this.contact.country.name = e.name;
    },

    //validation

    validateSelectedProspect() {
      if (
        this.multiProspects[0].selectedProspect === "" ||
        this.multiProspects[0].selectedProspect === null
      ) {
        // shake alert quand le prospect n'est pas rempli
        this.$swal({
          title: "Vous n'avez pas choisi de prospect ou client",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      }
    },

    //TopEnd

    topEnd() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Prospect ajouter avec succès",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    topEndN() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Prospect enregistré avec succès",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    retour() {
      this.$router.push("/liste-prospection");
    },
    //add new prospect
    async addNewProspect(bvModalEvt) {
      try {
        if (this.multiProspects.length === 0) {
          // shake alert quand le prospect n'est pas rempli
          this.$swal({
            title: "Vous n'avez pas choisi de prospect ou client",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__shakeX",
            },
            buttonsStyling: false,
          });
        }
        this.validateSelectedProspect();
        if (
          this.multiProspects[0].selectedProspect === "" ||
          this.multiProspects[0].selectedProspect === null
        ) {
          this.loading = false;
          bvModalEvt.preventDefault();
        } else {
          const addNewProspect = {
            prospection_id: this.prospections.id,
            count: this.multiProspects.length,
            item: this.multiProspects,
          };
          // console.log(newProspect)
          this.loading = true;
          await axios
            .post(URL.PROSPECT_NEW, addNewProspect)
            .then((response) => {
              if (response.data) {
                this.loading = false;
                this.topEnd();
                this.$router.push("/liste-prospection");
                //  for (let index = 0; index < response.data.newProspect.length; index++) {
                //      const element = response.data.newProspect[index];
                //       this.prospections.prospects.push(element)
                //  }

                // console.log(this.prospections)
              }
            });
        }
      } catch (error) {
        this.loading = false;
      }
    },

    //save prospect sideBar

    async saveProspect(bvModalEvt) {
      try {
        this.validateStatus();
        this.validateNom();
        this.validateContact();
        // this.if_exist();
        // Prevent modal from closing
        if (this.valideNom || this.valideContact) {
          bvModalEvt.preventDefault();
          // this.handleSubmit();
        } else {
          this.indicatifFinal = "+" + "" + this.contact.country.dialCode;
          console.log(this.indicatifFinal);
          if (this.selected.title == "Particulier") {
            this.status_id = "particulier";
          } else {
            this.status_id = "entreprise";
          }
          const config = {
            headers: {
              Accept: "application/json",
            },
          };
          const newProspect = {
            nom: this.nom,
            prenoms: this.prenom,
            contact: this.contactFinal,
            indicateur: this.indicatifFinal,
            localisation: JSON.parse(localStorage.getItem("place")),
            email: this.email,
            type_client: this.status_id,
            // prospection_id : this.selectedProspection.id,
            // prospection_name : this.selectedProspection.libelle
          };
          localStorage.removeItem("place");
          await axios
            .post(URL.PROSPECT_CREATE, newProspect, config)
            .then((response) => {
              this.userData = response.data.user;
              this.prospectList.unshift(this.userData);
              this.$refs.sidebarProspect.hide();
              this.topEndN();
            });
          this.nom = "";
          this.prenom = "";
          this.email = "";
          this.phone = "";
          this.localisation = "";
          this.contactFinal = "";
          this.indicatifFinal = "";
          this.status_id = "";
          this.selected = "Quel est votre status";
        }
      } catch (error) {
        console.log("error:", error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;
  margin: auto;
  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;
  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.add-btn {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: #450077;
}
.none {
  display: none;
}
.block {
  display: inline-block;
}
</style>
